<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BCard, BLink, BCardTitle, BCardText, BForm,
	BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { getAuth, signInWithPopup, GoogleAuthProvider, GithubAuthProvider, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

import firebaseError from "../../@utils/firebase-error";
import useJwt from '@/auth/jwt/useJwt'

export default {
	components: {
		VuexyLogo,
		// BSV
		BCard,
		BAlert,
		BLink,
		BCardTitle,
		BCardText,
		BForm,
		BButton,
		BFormInput,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BFormCheckbox,
		// validations
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			regEmail: '',
			password: '',
			status: '',

			// validation rules
			required,
			email,
		}
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
	},
	methods: {
		validationForm() {
			var ctx = this;

			var auth = getAuth();

			this.$refs.registerForm.validate().then(success => {
				if (success) {

					if (ctx.status != true) {
						ctx.$toast({
							component: ToastificationContent,
							props: {
								title: "Terms of use",
								text: "You must accept the terms of the site !",
								icon: 'AlertTriangleIcon',
								variant: 'danger',
							},
						})

						return;
					}

					createUserWithEmailAndPassword(auth, ctx.regEmail, ctx.password)
						.then(() => {
							ctx.$toast({
								component: ToastificationContent,
								props: {
									title: "Account Created",
									text: "Congratulations, your account has been created, please check your email to login!",
									icon: 'UserCheckIcon',
									variant: 'success',
								},
							})

							ctx.$router.push("/");
						})
						.catch((error) => {
							const errorCode = error.code;
							const errorMessage = error.message;
							console.log({ errorCode, errorMessage });

							ctx.$toast({
								component: ToastificationContent,
								props: {
									title: "Error",
									text: firebaseError(errorCode),
									icon: 'UserXIcon',
									variant: 'danger',
								},
							})
						});
				}
			})
		},
		registerOauth(service) {
			var ctx = this;
			var auth = getAuth();


			if (service == "google") {
				var provider = new GoogleAuthProvider();
				signInWithPopup(auth, provider)
					.then(() => {
						ctx.$router.push("/");

					}).catch((error) => {
						const errorCode = error.code;
						console.log(error)
						ctx.$toast({
							component: ToastificationContent,
							props: {
								title: "Error",
								text: firebaseError(errorCode),
								icon: 'UserXIcon',
								variant: 'danger',
							},
						})
					});
			}
			else if (service == "github") {
				var provider = new GithubAuthProvider();
				signInWithPopup(auth, provider)
					.then(() => {
						ctx.$router.push("/");
					}).catch((error) => {
						const errorCode = error.code;

						ctx.$toast({
							component: ToastificationContent,
							props: {
								title: "Error",
								text: firebaseError(errorCode),
								icon: 'UserXIcon',
								variant: 'danger',
							},
						})
					});
			}
		}
	},
	mounted() {
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">

			<!-- Register v1 -->
			<b-card class="mb-0">
				<b-link class="brand-logo">
					<vuexy-logo />
					<h2 class="brand-text text-primary ml-1">
						Enigma App
					</h2>
				</b-link>

				<b-card-title class="mb-1">
					Adventure starts here 🚀
				</b-card-title>

				<!-- form -->
				<validation-observer ref="registerForm">
					<b-form class="auth-register-form mt-2" @submit.prevent="validationForm">
						<!-- email -->
						<b-form-group label="Email" label-for="email">
							<validation-provider #default="{ errors }" name="Email" rules="required|email">
								<b-form-input id="email" v-model="regEmail" :state="errors.length > 0 ? false : null"
									name="register-email" placeholder="john@example.com" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<!-- password -->
						<b-form-group label="Password" label-for="password">
							<validation-provider #default="{ errors }" name="Password" rules="required">
								<b-input-group class="input-group-merge"
									:class="errors.length > 0 ? 'is-invalid' : null">
									<b-form-input id="password" v-model="password" :type="passwordFieldType"
										:state="errors.length > 0 ? false : null" class="form-control-merge"
										name="register-password" placeholder="············" />
									<b-input-group-append is-text>
										<feather-icon :icon="passwordToggleIcon" class="cursor-pointer"
											@click="togglePasswordVisibility" />
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<!-- checkbox -->
						<b-form-group>
							<b-form-checkbox id="register-privacy-policy" v-model="status" name="checkbox-1">
								I agree to
								<b-link>privacy policy & terms</b-link>
							</b-form-checkbox>
						</b-form-group>

						<!-- submit button -->
						<b-button variant="primary" block type="submit">
							Sign up
						</b-button>
					</b-form>
				</validation-observer>

				<b-card-text class="text-center mt-2">
					<span>Already have an account? </span>
					<b-link :to="{ name: 'login' }">
						<span>Sign in instead</span>
					</b-link>
				</b-card-text>

				<div class="divider my-2">
					<div class="divider-text">
						or
					</div>
				</div>

				<!-- social buttons -->
				<div class="auth-footer-btn d-flex justify-content-center">
					<b-button @click="registerOauth('google')" variant="google" href="javascript:void(0)">
						<i class="fa-brands fa-google"></i>
					</b-button>

					<b-button @click="registerOauth('github')" variant="github" href="javascript:void(0)">
						<i class="fa-brands fa-github"></i>
					</b-button>
				</div>
			</b-card>
			<!-- /Register v1 -->
		</div>
	</div>

</template>